import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';

const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Minim Efek Samping',
    subTitle:
      'Jadikan tubuh lebih bugar tanpa khawatir efek samping yang mengganggu! Akupunktur dan fisioterapi memberikan solusi tanpa rasa khawatir karena tidak melibatkan obat-obatan kimia. Dengan menggunakan jarum-jarum halus dan teknik terapi manual, risiko komplikasi sangat rendah, sehingga bisa merasa tenang dan nyaman selama menjalani terapi.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Tanpa Obat Kimia',
    subTitle:
      'Takut dengan obat-obatan yang memiliki banyak efek samping? Akupunktur dan fisioterapi adalah jawabannya! Terapi ini dilakukan secara alami tanpa campur tangan bahan kimia. Dengan teknik stimulasi tubuh dan latihan fisik yang menyenangkan, tubuhmu bisa sembuh secara alami. Jadi, selamat tinggal obat-obatan kimia, halo terapi alami!',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Terapi Alami',
    subTitle: `Akupunktur dan fisioterapi menawarkan perawatan yang harmonis dengan tubuh kita. Akupunktur merangsang titik-titik energi di tubuh untuk mengurangi rasa sakit, sedangkan fisioterapi membantu memperbaiki mobilitas dengan latihan-latihan seru. Terapi ini mendukung penyembuhan secara alami dan holistik, sehingga tubuhmu bisa merasa lebih baik secara keseluruhan.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Trend Pengobatan Terkini',
    subTitle:
      'Banyak orang sekarang beralih ke perawatan yang lebih alami dan holistik. Dengan semakin tingginya kesadaran akan efek samping obat-obatan, akupunktur dan fisioterapi jadi pilihan yang populer. Bergabunglah dengan tren terkini ini dan nikmati perawatan yang lebih alami dan menyenangkan!',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Mengobati Akar Masalah',
    subTitle:
      'Dibanding hanya mengobati gejala, akupunktur dan fisioterapi langsung menyasar akar masalah kesehatan. Akupunktur mengembalikan keseimbangan energi dalam tubuh dan merangsang penyembuhan alami, sementara fisioterapi memperbaiki postur dan fungsi tubuh. nikmati pemulihan jangka panjang dan kualitas hidup yang lebih baik.',
  },
];
const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
];
const faqData = [
  {
    title: 'Apa itu klinik Aku Sehat?',
    content:
      'Klinik Aku Sehat adalah klinik akupunktur dan fisioterapi di Makassar yang menawarkan layanan terapi saraf terjepit, dengan pendekatan holistik yang menggabungkan pengobatan tradisional dan modern.',
  },
  {
    title: 'Apa saja layanan yang ditawarkan di klinik Aku Sehat?',
    content:
      'Kami menawarkan berbagai layanan termasuk terapi saraf terjepit, akupunktur, fisioterapi, konsultasi kesehatan, program kebugaran, dan sesi edukasi kesehatan.',
  },
  {
    title: 'Apakah terapi di Aku Sehat aman?',
    content:
      'Ya, semua terapi yang kami tawarkan aman dan dilakukan oleh profesional terlatih dengan pengalaman dalam akupunktur dan fisioterapi.',
  },
  {
    title: 'Berapa lama durasi satu sesi terapi?',
    content:
      'Durasi satu sesi terapi biasanya berkisar antara 45 menit hingga 1 jam, tergantung pada jenis dan kebutuhan terapi.',
  },
  {
    title: 'Apakah saat terasa sakit saat di akupuntur?',
    content:
      'Akupuntur merupakan terapi dengan menggunakan jarum, jarum ini berfungsi menstimulasi respon saraf dan pembuluh darah. karena hanya untuk menstimulasi, jarum tersebut hanya menempel di permukaan kulit, sehingga rasa sakit itu sangat minim, bahkan tidak terasa.',
  },
];
const blogData = [
  {
    title:
      'Memahami Saraf Leher: Cara Mengatasi Saraf Terjepit di Leher',
    thumbUrl: 'images/blog/post_1.jpeg',
    date: 'March 12',
    btnText: 'Learn More',
    href: '/blog/saraf-terjepit-leher',
    socialShare: true,
  },
  {
    title: `Cara berkomunikasi dengan tubuh kita, Mengenal tanda tanda sebelum sakit`,
    thumbUrl: 'images/blog/post_2.jpeg',
    date: 'March 11',
    btnText: 'Learn More',
    href: '/blog/tanda-tanda-sakit',
    socialShare: true,
  },
  {
    title: 'Resiko Saraf Kejepit bagi Pegawai Kantor, dan Solusinya',
    thumbUrl: 'images/blog/post_3.jpeg',
    date: 'March 9',
    btnText: 'Learn More',
    href: '/blog/saraf-terjepit-di-kantor',
    socialShare: true,
  },
];
const awardData = [
  {
    title: 'Yin Yang Balancing Certified',
    subTitle:
      'Dr. Manaka Yin – Yang Balancing: Acupuncture & Japanese Moxibustion Level 1 - Level 3.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Certified hypnotherapist',
    subTitle:
      'Certified hypnotherapist (Cht), Indonesian Board of Hypnotherapy, 2016.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Teishin Master Basic Certified',
    subTitle:
      'Teishin Master Basic Course Takahiro Style Technique, TST Academy, 2018.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Edward Obaidey TJM Certified',
    subTitle:
      'Traditional Japanese Medicine level 1-2-3 with Edward Obaidey, 2019.',
    iconUrl: 'images/icons/award.svg',
  },
];
const departmentData = [
  {
    title: 'Fisioterapi',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '/departments/fisioterapi',
  },
  {
    title: 'Akupuntur',
    iconUrl: 'images/home_1/department_icon_2.svg',
    href: '/departments/akupuntur',
  },
  {
    title: 'Terapi Fisik',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '/departments/terapiFisik',
  },
  {
    title: 'Terapi Nafas',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '/departments/terapiNafas',
  },
];
export default function Home() {
  pageTitle('Beranda');
  return (
    <>
      <Hero
        title="Buktikan Baktimu, Solusi Sehat Alami untuk Orang Tua mu!"
        subTitle="Aku Sehat Akupuntur dan Fisioterapi membantu orang tua anda bebas dari masalah saraf terjepit yang mengganggu."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="Penjelasan Singkat"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: 'Konsultasi',
            subTitle: '+62 859-2198-5701',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Sosial Media',
            subTitle: 'Aku Sehat Akupuntur',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Lokasi',
            subTitle: 'Pelita VI C12',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Hubungi Kami"
        btnUrl="https://wa.me/6285921985701"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Mengapa Akupuntur dan Fisioterapi" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
      <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="Tentang kami"
          subTitle="Aku Sehat Akupuntur"
          featureList={[
            {
              featureListTitle:
                'Klinik untuk menstimulasi sehat alami, tanpa obat-obatan kimia',
              featureListSubTitle:
                'Akupuntur medis merupakan pengobatan tradisional asal china yang dikombinasikan dengan pengobatan kedokteran modern, yang bertujan untuk menstimulasi kesahatan alami dari tubuh kita sendiri .',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Sertifikasi dan Pelatihan" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="apa kata"
          sectionTitleDown="Pasien Aku Sehat"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Jangan Biarkan Kesehatanmu menjadi prioritas terakhir!"
          subTitle="Jadwalkan kunjunganmu Sekarang juga!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Update Terkini"
          sectionTitleUp="ARTIKEL BLOG"
          data={blogData}
        />
      </Section>
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Reservasi"
          sectionTitleUp="BOOKING"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Sering Tanyakan"
          sectionTitleUp="Apa yang orang"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection 
        data={brandData}
        sectionTitle="Partner" />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
