import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';

const departmentData = [
  {
    title: 'Terapi Saraf Terjepit',
    subTitle:
      'Terapi saraf terjepit melalui akupunktur dan fisioterapi alami membantu meredakan nyeri dan memperbaiki gerakan.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Rehabilitasi Pasca Stroke',
    subTitle:
      'Rehabilitasi pasca stroke mengoptimalkan pemulihan fisik dan mental melalui terapi terarah dan latihan berkelanjutan.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Rheumatik & Arthritis',
    subTitle:
      'Terapi alami kami mengurangi gejala rheumatik dan osteoartritis, agar pasien tetap aktif & nyaman.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Cidera Olahraga',
    subTitle:
      'Pemulihan cedera olahraga yang efektif melalui latihan rehabilitasi dan teknik akupunktur yang terbukti.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/fisioterapi',
  },
  {
    title: 'Program Hamil Alami',
    subTitle:
      'Tingkatkan kesuburan secara alami dengan program hamil berbasis akupunktur dan terapi holistik.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
];

const featureListData = [
  {
    title: 'Pendekatan Terapi Alami',
    subTitle:
      'Klinik kami mengutamakan terapi alami seperti akupunktur dan fisioterapi, tanpa obat kimia. Terapi ini aman dan efektif, meminimalkan efek samping serta mendukung pemulihan alami tubuh, memberikan solusi kesehatan holistik bagi pasien.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Tim Medis Berpengalaman',
    subTitle:
      'Tim kami terdiri dari praktisi berlisensi dan berpengalaman yang berdedikasi untuk memberikan perawatan terbaik. Dengan pengetahuan mendalam dan keahlian, kami memastikan setiap pasien mendapatkan perhatian dan penanganan yang tepat dan personal.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Fasilitas Modern <br> & Nyaman',
    subTitle:
      'Klinik kami dilengkapi dengan peralatan modern dan suasana yang nyaman. Kami memastikan pasien merasa tenang dan aman selama perawatan, mendukung proses pemulihan yang lebih cepat dan efektif dengan standar keamanan tinggi.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Layanan Terpadu <br> & Holistik',
    subTitle:
      'Kami menawarkan layanan terpadu yang mencakup berbagai terapi dan konsultasi kesehatan. Dengan pendekatan holistik, kami tidak hanya mengobati gejala tetapi juga mencari akar masalah kesehatan, memastikan pemulihan jangka panjang yang optimal.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const funFactData = [
  { number: 3, suffix: '+', title: 'Tahun Berjalan' },
  { number: 90, suffix: '%', title: 'Rating Kepuasan Pasien' },
  { number: 1759, suffix: '+', title: 'Pasien yang sudah ditangani' },
  { number: 4, suffix: '+', title: 'Tim Medis' },
  { number: 8, suffix: '+', title: 'Rata rata kunjungan pasien per hari' },
];

const teamData = [
  {
    imgUrl: 'images/about/doctor_1.png',
    name: 'Kurnia Sucitra, S.Tr.Ft',
    designation: 'Fisioterapis Berlisensi',
    description:
      'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_2.png',
    name: 'dr. Aswadi Ibrahim, Sp.Ak',
    designation: 'Dokter Spesialis Akupuntur',
    description:
      'With expertise in treating acute illnesses and injuries in medicine physician',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_3.png',
    name: 'Fitriani, S.Tr.Akup',
    designation: 'Akupuntur Terapis Berlisensi',
    description:
      'With experience in managing complex medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

const galleryData = [
  { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
];
const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Yin Yang Balancing Certified',
  },
  { iconUrl: '/images/icons/award.svg', title: 'Certified Hypnotherapist' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Teishin Master Basic Certified',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Edward Obaidey TJM Certified',
  },
];

export default function About() {
  pageTitle('Tentang Kami');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Selamat Datang <br />di Klinik Akupuntur Pertama di Indonesia Timur"
        subTitle="Klinik Aku Sehat Akupuntur dan Fisioterapi, merupakan klinik dengan spesialisasi penanganan saraf terjepit dan pemulihan pasca stroke"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Aku Sehat Akupuntur"
          sectionTitleUp="Layanan"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Mengapa Memilih Kami"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Tim Terapis"
          sectionTitleUp="Perkenalkan"
          data={teamData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Sarana dan <br />Fasilitas"
          sectionTitleUp="Mengenal"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Sertifikasi <br />dan Penghargaan"
          sectionTitleUp="Certified"
          sectionSubTitle="Kami berkomitmen memberikan perawatan terbaik <br />dengan pelayanan prima."
          data={awardData}
        />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Jangan Biarkan Kesehatanmu <br />Tidak mendapatkan tempat!"
          subTitle="Jadwalkan konsultasimu di Klinik Aku Sehat <br /> Hari ini!"
          center
        />
      </Section>
    </>
  );
}
