import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import PricingSection from '../Section/PricingSection';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';
import BannerSectionStyle10 from '../Section/BannerSection/BannerSectionStyle10';
const pricingData = [
  {
    title: 'Membership',
    subTitle:
      'Akses eksklusif ke akupunktur dan fisioterapi secara pribadi untuk kesehatan dan kebugaran Anda.',
    price: 'Rp.199k',
    period: '/lifetime',
    featureList: [
      'untuk 1 orang',
      'Disc Up to 20% non paket',
      'Diskon Up to 10% untuk paket',
      'Gratis Konsultasi',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Membership Pro',
    subTitle:
      'Membership Ideal untuk pasangan, yang ingin menjaga kesehatan bersama dengan terapi alami.',
    price: 'Rp.299k',
    period: '/lifetime',
    featureList: [
      'untuk 2 orang',
      'Disc Up to 20% non paket',
      'Diskon Up to 10% untuk paket',
      'Gratis Konsultasi',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: true,
  },
  {
    title: 'Membership Platinum',
    subTitle:
      'Membership untuk 4 orang. Lindungi kesehatan keluarga dengan perawatan holistik yang aman dan alami.',
    price: 'Rp.399k',
    period: '/lifetime',
    featureList: [
      'untuk 4 orang',
      'Disc Up to 20% non paket',
      'Diskon Up to 10% untuk paket',
      'Gratis Konsultasi',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Terapi Akupuntur',
    subTitle:
      'Terapi akupuntur meredakan nyeri dan meningkatkan keseimbangan energi tubuh. Solusi alami yang aman dan efektif, tanpa obat-obatan kimia.',
    price: 'Rp.300k',
    period: '/kunjungan',
    featureList: [
      'Terapi Akupuntur',
      'Terapi Akupresur',
      'Elektro-Akupuntur',
      'Program Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: true,
  },
  {
    title: 'Paket Akupuntur Intensif',
    subTitle:
      'Paket akupuntur intensif untuk hasil maksimal. Terapi ini meredakan nyeri, mengembalikan keseimbangan energi.',
    price: 'Rp.1.6 jt',
    period: '/6 sesi',
    featureList: [
      'Terapi Akupuntur',
      'Terapi Akupresur',
      'Elektro-Akupuntur',
      'Program Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Paket Akupuntur Intensif Pro',
    subTitle:
      'Paket akupuntur intensif untuk pemulihan mendalam. Fokus pada peningkatkan keseimbangan energi tubuh untuk hasil jangka panjang.',
    price: 'Rp.3.3 jt',
    period: '/12 sesi',
    featureList: [
      'Terapi Akupuntur',
      'Terapi Akupresur',
      'Elektro-Akupuntur',
      'Program Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Terapi Fisioterapi',
    subTitle:
      'Fisioterapi membantu memulihkan mobilitas latihan fisik. Cocok untuk pemulihan cedera dan pencegahan cedera berulang.',
    price: 'Rp.200k',
    period: '/kunjungan',
    featureList: [
      'Terapi Fisioterapi',
      'Terapi Fisik',
      'Terapi Okupasi',
      'Fitness Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: true,
  },
  {
    title: 'Paket Fisioterapi Intensif',
    subTitle:
      'Paket fisioterapi intensif untuk mempercepat pemulihan cedera. Menggunakan latihan fisik, membantu meningkatkan fungsi fisik, mengurangi nyeri, dan mencegah cedera berulang.',
    price: 'Rp.1.1 jt',
    period: '/6 sesi',
    featureList: [
      'Terapi Fisioterapi',
      'Terapi Fisik',
      'Terapi Okupasi',
      'Fitness Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Paket Fisioterapi Intensif Pro',
    subTitle:
      'Paket sesi fisioterapi intensif menawarkan perawatan rehabilitasi jangka panjang, meningkatkan mobilitas, dan mengembalikan fungsi fisik optimal secara menyeluruh.',
    price: 'Rp.2 jt',
    period: '/12 sesi',
    featureList: [
      'Terapi Fisioterapi',
      'Terapi Fisik',
      'Terapi Okupasi',
      'Fitness Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: `Paket Aku-Fisio`,
    subTitle: `Paket terapi kombinasi akupuntur dan fisioterapi, sangat powerful untuk pemulihan stroke dan saraf terjepit.`,
    price: 'Rp.2.6 jt',
    period: '/6 sesi',
    featureList: [
      `Terapi Akupuntur`,
      'Terapi Fisioterapi',
      'Kombinasi Terapi',
      'Program Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: true,
  },
  {
    title: 'Paket Aku-Fisioterapi Pro',
    subTitle:
      'Paket terapi kombinasi akupuntur dan fisioterapi yang intensif, direkomendasikan untuk pemulihan stroke dan saraf terjepit yang kronis.',
    price: 'Rp.4.4 jt',
    period: '/12 sesi',
    featureList: [
      `Terapi Akupuntur`,
      'Terapi Fisioterapi',
      'Kombinasi Terapi',
      'Program Support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Konsultasi Dokter',
    subTitle:
      'Optimized for athletes. Access sports injury assessments, physiotherapy, and expert advice for peak performance and recovery.',
    price: 'Rp.150k',
    period: '/sesi',
    featureList: [
      'Assesment Dokter',
      'Pemeriksaan Fisik',
      'Konsultasi Dokter',

    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: true,
  },
];
const faqData = [
  {
    title: 'What services does ProHealth offer?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I schedule an appointment with ProHealth?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'Do you accept insurance?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'What should I bring to my appointment?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I request a prescription refill?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
];
export default function PricingPlan() {
  pageTitle('Pricing Plan');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/pricing_plan/banner_img.png"
        title="Rencanakan Biaya Sehatmu <br> Sekarang"
        subTitle="Dapatkan pilihan paket dan membership terbaik untuk kamu dan keluarga"
        btnText="Daftar Sekarang"
        btnUrl="/contact"
      />
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Pilih Paket Terapi dan <br />Membershipmu sekarang "
          data={pricingData}
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/pricing_plan/faq_img.jpeg"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className="cs_footer_margin_0"
      >
        <BannerSectionStyle10
          imgUrl="/images/pricing_plan/banner_img_2.png"
          title="Pilih yang terbaik untuk investasi kesehatan dirimu dan keluarga!"
        />
      </Section>
    </>
  );
}
