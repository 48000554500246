import React from 'react';
import SideMenuWidget from '../Widget/SideMenuWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import NewsletterStyle5 from '../Widget/NewsletterStyle5';

const categoryData = [
  {
    title: 'Artikel Kesehatan',
    url: '/',
  },
  {
    title: 'Tren Kesehatan',
    url: '/',
  },
  {
    title: 'Pengembangan Diri',
    url: '/',
  },
];
const recentPostData = [
  {
    title: `Memahami Saraf Leher: Cara Mengatasi Saraf Terjepit di Leher`,
    author: 'Ala Unas B.',
    date: 'August 10, 2023',
    href: '/blog/saraf-terjepit-leher',
  },
  {
    title: `7 Tanda Tubuh Akan Sakit dan Cara Mencegahnya`,
    author: 'Ala Unas B.',
    date: 'August 09, 2022',
    href: '/blog/tanda-tanda-sakit',
  },
  {
    title: `Resiko Saraf Kejepit bagi Pegawai Kantor, dan Solusinya`,
    author: 'Ala Unas B',
    date: 'August 08, 2022',
    href: '/blog/saraf-terjepit-di-kantor',
  },
  {
    title: `Tips Menjaga Kesehatan Jantung Akibat Makan Daging Saat Idul Adha`,
    author: 'Ala Unas B',
    date: 'August 07, 2022',
    href: '/blog/jantung-idul-adha',
  },
  {
    title: `Tips Mengatasi Cedera Saraf Kejepit Akibat Olahraga`,
    author: 'Ala Unas B',
    date: 'August 10, 2023',
    href: '/blog/cidera-olahraga',
  },
];

export default function Sidebar() {
  return (
    <div className="cs_sidebar">
      <div className="cs_sidebar_item widget_categories">
        <SideMenuWidget title="Popular Categories" data={categoryData} />
      </div>
      <div className="cs_sidebar_item">
        <RecentPostWidget title="Popular Articles" data={recentPostData} />
      </div>
      <div className="cs_sidebar_item widget_categories">
        <NewsletterStyle5 title="Newsletter Sign Up Form" />
      </div>
    </div>
  );
}
