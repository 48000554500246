import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Post from '../Post';
import Sidebar from '../Sidebar';
import AuthorWidget from '../Widget/AuthorWidget';
import Comments from '../Widget/Comments'; // Import Comments
import { Helmet } from 'react-helmet';
import { pageTitle } from '../../helpers/PageTitle';
import blogPosts from './BlogData';
import authorData from '../Widget/Author';

const tags = [
  { tag: 'Fisioterapi', href: '/blog/blog-details' },
  { tag: 'Akupuntur', href: '/blog/blog-details' },
  { tag: 'Terapi Fisik', href: '/blog/blog-details' },
  { tag: 'Edukasi Kesehatan', href: '/blog/blog-details' },
];
const relatedBlog = [
  {
    title: 'Resiko Saraf Kejepit bagi Pegawai Kantor, dan Solusinya',
    thumbUrl: '/images/blog/post_1.jpeg',
    date: 'March 12',
    btnText: 'Pelajari Selanjutnya..',
    href: '/blog/saraf-terjepit-di-kantor',
  },
  {
    title: `Tips Mengatasi Cedera Saraf Kejepit Akibat Olahraga`,
    thumbUrl: '/images/blog/post_2.jpeg',
    date: 'March 11',
    btnText: 'Pelajari Selanjutnya..',
    href: '/blog/cidera-olahraga',
  },
  {
    title: 'Cara Alami Mengatasi Saraf Terjepit tanpa Efek Samping',
    thumbUrl: '/images/blog/post_3.jpeg',
    date: 'March 9',
    btnText: 'Pelajari Selanjutnya..',
    href: '/blog/cara-alami',
  },
];

export default function BlogDetails() {
  pageTitle('Artikel');

  const { blogId } = useParams(); // Get blogId from URL
  const blogPost = blogPosts.find(post => post.blogId === blogId); // Find the blog post

  if (!blogPost) {
    return <h2>Blog post not found</h2>; // Menampilkan pesan jika blog tidak ditemukan
  }

  const author = authorData.find(author => author.id === blogPost.authorId); // Find the author of the blog post

  return (
    <>
      <Helmet>
        <title>{blogPost.title}</title>
        <link rel="canonical" href={blogPost.ogUrl} />
        <meta name="description" content={blogPost.description} />
        <meta property="og:title" content={blogPost.ogTitle} />
        <meta property="og:description" content={blogPost.ogDescription} />
        <meta property="og:image" content={blogPost.ogImage} />
        <meta property="og:url" content={blogPost.ogUrl} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blogPost.ogTitle} />
        <meta name="twitter:description" content={blogPost.ogDescription} />
        <meta name="twitter:image" content={blogPost.ogImage} />
        <meta name="twitter:url" content={blogPost.ogUrl} />
      </Helmet>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={blogPost.title} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags">
              {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div>
            <div className="cs_blog_details_date">
              {blogPost.date} | {author?.name}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>Share:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={blogPost.ogImage}
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <h2>{blogPost.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
              {/* Blockquote section */}
              <blockquote
                style={{
                  backgroundImage: `url(${blogPost.blockquote.backgroundImageUrl})`,
                }}
              >
                <p>{blogPost.blockquote.text}</p>
              </blockquote>
            </div>
            <Spacing md="85" />
            {author && (
              <AuthorWidget
                imgUrl={author.imgUrl}
                name={author.name}
                description={author.description}
              />
            )}
            <Spacing md="110" />
            <Comments title="Comments" /> {/* Use Comments component */}
            <Spacing md="92" />
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
        <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div>
      </div>
      <Spacing md="200" xl="150" lg="110" />
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Konsultasikan Kesehatanmu <br />Hari ini!"
          subTitle="Jadwalkan Kedatanganmu <br />melalui Whats App!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}