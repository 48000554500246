import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../../services/api'; // Import Axios instance
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppointmentForm = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [reasonForVisit, setReasonForVisit] = useState('');
  const [department, setDepartment] = useState('');

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!appointmentTime) {
      toast.error('Waktu janji harus dipilih.');
      return;
    }
    try {
      const formattedTime = formatTime(appointmentTime);
      const response = await api.post('/appointments', {
        name,
        phoneNumber,
        appointmentDate,
        appointmentTime: formattedTime,
        reasonForVisit,
        department,
      });
      console.log('Appointment created:', response.data);
      toast.success('Reservasi berhasil!');
    } catch (error) {
      console.error('Error creating appointment:', error.response ? error.response.data : error.message);
      toast.error('Reservasi gagal, coba pilih waktu lain.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Nama</label>
        <input 
          type="text" 
          className="cs_form_field" 
          placeholder="Nama Anda"
          value={name}
          onChange={(e) => setName(e.target.value)} required
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Whats App</label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="08123456789"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)} required
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Pilih Tanggal
        </label>
        <div className="cs_with_icon_input">
          <DatePicker
            selected={appointmentDate}
            onChange={date => setAppointmentDate(date)}
            dateFormat="yyyy/MM/dd/"
            minDate={new Date()}
            isClearable
            placeholderText="Pilih Tanggal"
          />
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Pilih Waktu
        </label>
        <div className="cs_with_icon_input">
          <select
            className="cs_form_field cs_timepicker"
            value={appointmentTime}
            onChange={(e) => setAppointmentTime(e.target.value)}
          >
            <option value="15:00">15.00</option>
            <option value="15:30">15.30</option>
            <option value="16:00">16.00</option>
            <option value="16:30">16.30</option>
            <option value="17:00">17.00</option>
            <option value="17:30">17.30</option>
            <option value="18:00">18.00</option>
          </select>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Rencana Reservasi Untuk
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="kunjunganBaru"
              value="Kunjungan Baru"
              checked={reasonForVisit === 'Kunjungan Baru'}
              onChange={(e) => setReasonForVisit(e.target.value)}
            />
            <label className="cs_radio_label" htmlFor="kunjunganBaru">
              Kunjungan Baru
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="kunjunganLama"
              value="Kunjungan Lama"
              checked={reasonForVisit === 'Kunjungan Lama'}
              onChange={(e) => setReasonForVisit(e.target.value)}
            />
            <label className="cs_radio_label" htmlFor="KunjunganLama">
              Kunjungan Biasa
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="kunjunganPaket"
              value="Kunjungan Paket"
              checked={reasonForVisit === 'Kunjungan Paket'}
              onChange={(e) => setReasonForVisit(e.target.value)}
            />
            <label className="cs_radio_label" htmlFor="kunjunganPaket">
              Kunjungan Paket
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Terapi</label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="Akupuntur"
              value="Akupuntur"
              checked={department === 'Akupuntur'}
              onChange={(e) => setDepartment(e.target.value)}
            />
            <label className="cs_radio_label" htmlFor="Akupuntur">
              Akupuntur
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="Fisioterapi"
              value="Fisioterapi"
              checked={department === 'Fisioterapi'}
              onChange={(e) => setDepartment(e.target.value)}
            />
            <label className="cs_radio_label" htmlFor="Fisioterapi">
              Fisioterapi
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="TerapiFisik"
              value="Terapi Fisik"
              checked={department === 'Terapi Fisik'}
              onChange={(e) => setDepartment(e.target.value)}
            />
            <label className="cs_radio_label" htmlFor="TerapiFisik">
              Paket lain
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default AppointmentForm;