import React from 'react';

export default function Pagination({ pageNumbers, paginate, currentPage, totalPages }) {
  return (
    <ul className="cs_pagination_box">
      <li>
        <button
          className="cs_pagination_arrow cs_center"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img src="images/icons/left_arrow_blue.svg" alt="Icon" />
        </button>
      </li>
      {pageNumbers.map(number => (
        <li key={number}>
          <button
            onClick={() => paginate(number)}
            className={`cs_pagination_item cs_center ${currentPage === number ? 'active' : ''}`}
          >
            {number}
          </button>
        </li>
      ))}
      <li>
        <button
          className="cs_pagination_arrow cs_center"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img src="images/icons/right_arrow_blue.svg" alt="Icon" />
        </button>
      </li>
    </ul>
  );
}