import React, { useState } from 'react';
import CommentsWidget from './CommentsWidget';
import ReplyWidget from './ReplyWidget';

export default function Comments() {
  const [comments, setComments] = useState([
    {
      id: 1,
      author: 'Aswadi Ibrahim',
      avatar: 'AI',
      date: 'August 24, 2023',
      content: 'Mantap Mas.',
    },
  ]);

  const addComment = (author, content) => {
    const newComment = {
      id: comments.length + 1,
      author,
      avatar: 'AD', // Default avatar
      date: new Date().toLocaleString(),
      content,
    };
    setComments([...comments, newComment]);
  };

  return (
    <div>
      <CommentsWidget title="Comments" comments={comments} />
      <ReplyWidget title="Leave a Reply" addComment={addComment} />
    </div>
  );
}