import React from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';

export default function CommentsWidget({ title, comments = [] }) { // Default value for comments
  return (
    <div id="comments" className="comments-area">
      <h2 className="comments-title cs-semi_bold cs_fs_24 cs_semibold mb-0">
        {title}
      </h2>
      <Spacing md="45" />
      <ol className="comment-list">
        {comments.map((comment) => (
          <li key={comment.id} className="comment">
            <div className="comment-body">
              <div className="comment-author vcard">
                <div className="avatar">{comment.avatar}</div>
                <Link to="/" className="url">
                  {comment.author}
                </Link>
              </div>
              <div className="comment-meta">
                <Icon icon="fa6-solid:clock" />
                <Link to="/">{comment.date}</Link>
              </div>
              <p>{comment.content}</p>
              <div className="reply">
                <Link className="comment-reply-link" to="/">
                  Reply
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ol>
      <Spacing md="45" />
    </div>
  );
}