import React, { useState } from 'react';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';

const blogData = [
  {
    title:
      'Utamakan Kesehatan Anda di Musim Kemarau',
    thumbUrl: 'images/blog/musim-kemarau.jpg',
    date: 'September 12, 2024',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/musim-kemarau',
    socialShare: true,
  },
  {
    title:
      'Memahami Saraf Leher: Cara Mengatasi Saraf Terjepit di Leher',
    thumbUrl: 'images/blog/post_1.jpeg',
    date: 'Agustus 28, 2024',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/saraf-terjepit-leher',
    socialShare: true,
  },
  {
    title: `Cara berkomunikasi dengan tubuh kita, tahu sebelum sakit`,
    thumbUrl: 'images/blog/post_2.jpeg',
    date: 'August 12, 2024',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/tanda-tanda-sakit',
    socialShare: true,
  },
  {
    title: 'Resiko Saraf Kejepit bagi Pegawai Kantor, dan Solusinya',
    thumbUrl: 'images/blog/post_3.jpeg',
    date: 'August 7, 2024',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/saraf-terjepit-di-kantor',
    socialShare: true,
  },
  {
    title: 'Tips Menjaga Kesehatan Jantung Akibat Makan Daging Saat Idul Adha',
    thumbUrl: 'images/blog/post_4.jpeg',
    date: 'March 8',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/jantung-idul-adha',
    socialShare: true,
  },
  {
    title: 'Tips Mengatasi Cedera Saraf Kejepit Akibat Olahraga',
    thumbUrl: 'images/blog/post_5.jpeg',
    date: 'March 5',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/cidera-olahraga',
    socialShare: true,
  },
  {
    title: 'Cara Alami Mengatasi Saraf Terjepit tanpa Efek Samping',
    thumbUrl: 'images/blog/post_6.jpeg',
    date: 'March 2',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/cara-alami',
    socialShare: true,
  },
  {
    title: 'Perawatan Terbaik untuk Saraf Terjepit di Klinik Spesialis',
    thumbUrl: 'images/blog/post_7.jpeg',
    date: 'Feb 28',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/klinik-saraf-terjepit',
    socialShare: true,
  },
  {
    title: 'Penyebab dan Penanganan Saraf Terjepit yang Efektif',
    thumbUrl: 'images/blog/post_8.jpeg',
    date: 'Feb 26',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/terapi-efektif',
    socialShare: true,
  },
  {
    title: 'Cara Mengobati Saraf Kejepit di Pinggang',
    thumbUrl: 'images/blog/post_9.jpeg',
    date: 'Feb 25',
    btnText: 'Baca Selengkapnya..',
    href: '/blog/nyeri-pinggang',
    socialShare: true,
  },
];

export default function Blog() {
  pageTitle('Blog');
  
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = blogData.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Kesehatan dan Kebugaran" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2
          data={currentArticles}
          paginate={paginate}
          totalArticles={blogData.length}
          articlesPerPage={articlesPerPage}
          currentPage={currentPage}
        />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Jangan Biarkan <br />Sakitmu!"
          subTitle="Jadwalkan terapi terbaikmu <br />stimulasi sehatmu sekarang!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}