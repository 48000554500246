import { Icon } from '@iconify/react';
import React from 'react';
import '../../sass/common/_contactInfoWidget.scss'; // Pastikan Anda membuat file CSS ini

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget">
      <li className="contact-item">
        <a href="https://maps.app.goo.gl/oXAhrV6w3rE1Tpu79" className="contact-link">
          <i className="cs_accent_bg contact-icon">
            <Icon icon="ep:location" />
          </i>
          <span className="contact-text">Jl. Pelita VI No. C12 Makassar</span>
        </a>
      </li>
      <li className="contact-item">
        <a href="https://wa.me/625921985701" className="contact-link">
          <i className="cs_accent_bg contact-icon">
            <Icon icon="fluent:call-24-regular" />
          </i>
          <span className="contact-text">+62 859-2198-5701</span>
        </a>
      </li>
      <li className="contact-item">
        <a href="mailto:mail@akusehat.co.id" className="contact-link">
          <i className="cs_accent_bg contact-icon">
            <Icon icon="bi:envelope" />
          </i>
          <span className="contact-text">mail@akusehat.co.id</span>
        </a>
      </li>
    </ul>
  );
}