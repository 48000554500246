import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
const teamData = [
  {
    imgUrl: '/images/doctors/doctor_1.png',
    department: 'Fisioterapi',
    name: 'Kurnia Sucitra S.Tr.Ft',
    designation: 'Terapis Fisioterapi',
    description:
      'Fisioterapis alumni Poltekkes Kemenkes Makassar. yang berpengalaman menangani kasus saraf terjepit dan pemulihan stroke ',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'fisioterapi',
    //href: '/doctors/doctor-details',
  },
  {
    imgUrl: '/images/doctors/doctor_2.png',
    department: 'Akupuntur',
    name: 'dr. Aswadi Ibrahim Sp.Ak',
    designation: 'Dokter Spesialis Akupuntur',
    description:
      'Dokter alumni Universitas Hasanuddin, dan Spesialis Akupuntur dari Universitas Indonesia',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Akupuntur',
    //href: '/doctors/doctor-details',
  },
  {
    imgUrl: '/images/doctors/doctor_3.png',
    department: 'Akupuntur',
    name: 'Fitriani S.Tr.Akup',
    designation: 'Akupuntur Terapis',
    description:
      'Akupuntur Terapis, Alumni Ilmu terapan akupuntur, Poltekkes Kemenkes Surakarta.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Akupuntur',
    //href: '/doctors/doctor-details',
  },
  {
    imgUrl: '/images/doctors/doctor_4.png',
    department: 'Terapi Nafas',
    name: 'Rara Putri Pertiwi Amd. Kep',
    designation: 'Perawat dan Terapis Nafas',
    description:
      'Dr. Patel is a compassionate pediatrician with over 15 years of experience. She is board-certified in pediatrics and has a special interest in preventive care and childhood development.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Terapi Nafas',
    //href: '/doctors/doctor-details',
  },
];

export default function Doctors() {
  pageTitle('Tim Medis');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/doctors/banner_img.png"
        title="Perkenalkan <br />Tim Medis Kami"
        subTitle="Tim Medis, Dokter dan Terapis<br />Klinik Aku Sehat Akupuntur dan Fisioterapi"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_2.jpeg"
          title="Jangan Biarkan Kesehatanmu <br />Tidak punya waktu !"
          subTitle="Jadwalkan Konsultasimu di Klinik Aku Sehat Hari Ini!"
        />
      </Section>
    </>
  );
}
