import React from 'react';
import BannerSectionStyle7 from '../Section/BannerSection/BannerSectionStyle7';
import Section from '../Section';
import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { useParams } from 'react-router-dom';

const departmentDetailsData = {
  akupuntur: {
    id: 1,
    title: 'Akupuntur',
    subTitle:
      'Akupunktur adalah teknik pengobatan tradisional yang berasal dari Tiongkok, yang melibatkan penyisipan jarum-jarum tipis ke dalam kulit pada titik-titik tertentu di tubuh.',
    paragraf: 
      'Tujuan dari prosedur ini adalah untuk mengimbangi aliran energi atau Qi (diucapkan "chi") dalam tubuh. Menurut teori tradisional Tiongkok, gangguan pada aliran Qi dapat menyebabkan berbagai macam penyakit atau rasa sakit.',
    imgUrl: '/images/departments/department_img_1.png',
  },
  fisioterapi: {
    id: 2,
    title: 'Fisioterapi',
    subTitle:
      'Fisioterapi adalah disiplin ilmu kesehatan yang berfokus pada pemulihan, pemeliharaan, dan peningkatan mobilitas serta fungsi tubuh melalui teknik-teknik fisik. Terapi ini dilakukan oleh fisioterapis yang terlatih untuk mendiagnosis, dan merancang program perawatan yang disesuaikan dengan kebutuhan individu.',
    imgUrl: '/images/departments/department_img_2.png',
    paragraf: 
      'Salah satu tujuan utama fisioterapi adalah untuk mengurangi rasa sakit dan meningkatkan fungsi fisik pasien. Ini sangat bermanfaat bagi mereka yang mengalami cedera, menderita penyakit kronis seperti arthritis, atau baru saja menjalani operasi. Dengan teknik-teknik seperti pijat, manipulasi sendi, dan latihan penguatan otot, fisioterapi membantu memperbaiki mobilitas.',
  },
  terapiFisik: {
    id: 3,
    title: 'Terapi Fisik',
    subTitle:
      'The Terapi Fisik Department provides specialized physical therapy treatments to address chronic pain and physical impairments.',
    imgUrl: '/images/departments/department_img_3.png',
  },
  terapiNafas: {
    id: 4,
    title: 'Terapi Nafas',
    subTitle:
      'The Terapi Fisik Department provides specialized physical therapy treatments to address chronic pain and physical impairments.',
    imgUrl: '/images/departments/department_img_4.png',
  },
};

const featureData = [
  {
    title: 'Fisioterapi',
    subTitle:
      'The Fisioterapi Department offers physical therapy services to help patients recover from injuries and improve mobility.',
    iconUrl: '/images/departments/icon_2.svg',
  },
  {
    title: 'Akupuntur',
    subTitle:
      'The Akupuntur Department provides traditional acupuncture treatments to help alleviate pain and promote overall wellness.',
    iconUrl: '/images/departments/icon_1.svg',
  },
  {
    title: 'Terapi Fisik',
    subTitle:
      'The Terapi Fisik Department provides specialized physical therapy treatments to address chronic pain and physical impairments.',
    iconUrl: '/images/departments/icon_3.svg',
  },
  {
    title: 'Terapi Nafas',
    subTitle:
      'The Terapi Fisik Department provides specialized breathing therapy treatments to address chronic pain and physical impairments.',
    iconUrl: '/images/departments/icon_4.svg',
  },
];

const doctorData = [
  {
    imgUrl: '/images/departments/related_doctor_1.jpeg',
    name: 'Kurnia Sucitra,S.Tr.Ft',
    designation: 'Fisioterapis Berlisensi',
    description:
      'Alumni Politeknik Kesehatan Makassar<br />medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    availableUrl: '/',
    callUrl: '/',
    chatUrl: '/',
    btnText: 'Booking',
    btnUrl: '/appointments',
    departmentId: 1,
  },
  {
    imgUrl: '/images/departments/related_doctor_2.jpeg',
    name: 'Dr. Aswadi Ibrahim, Sp.Ak',
    designation: 'Dokter Spesialis Akupuntur',
    description:
      'With experience in managing complex <br />medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    availableUrl: '/',
    callUrl: '/',
    chatUrl: '/',
    btnText: 'Booking',
    btnUrl: '/',
    departmentId: 2,
  },
  {
    imgUrl: '/images/departments/related_doctor_3.jpeg',
    name: 'Fitriani, S.Tr.Akup',
    designation: 'Terapis Akupuntur Berlisensi',
    description:
      'With experience in managing complex <br />medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    availableUrl: '/',
    callUrl: '/',
    chatUrl: '/',
    btnText: 'Booking',
    btnUrl: '/',
    departmentId: 3,
  },
  {
    imgUrl: '/images/departments/related_doctor_4.jpeg',
    name: 'Rara Putri Pratiwi, A.Md.Kep',
    designation: 'Certified Breathing Therapist',
    description:
      'With experience in managing complex <br />medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    availableUrl: '/',
    callUrl: '/',
    chatUrl: '/',
    btnText: 'Booking',
    btnUrl: '/',
    departmentId: 4,
  },
];
export default function DepartmentDetails() {
  const { departmentId } = useParams();
  const department = departmentDetailsData[departmentId];

  if (!department) {
    return <div>Department not found</div>;
  }

  pageTitle(department.title);

  return (
    <>
      <BreadcrumbStyle2 />
      <Section topMd={135} topLg={100} topXl={100}>
        <AboutSectionStyle2
          title={department.title}
          subTitle={department.subTitle}
          paragraf={department.paragraf}
          imgUrl={department.imgUrl}
        />
      </Section>

      <Section topMd={170} topLg={145} topXl={90}>
        <FeaturesSectionStyle3
          sectionTitle="Perawatan Lainnya"
          sectionTitleUp="Jenis-jenis"
          data={featureData}
        />
      </Section>
      <Section topMd={200} topLg={150} topXl={100}>
        <TeamSectionStyle3 sectionTitle="Terapis" data={doctorData} />
      </Section>

      {/* Start Appointment Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={190}
        bottomLg={145}
        bottomXl={110}
        id="appointment"
      >
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle7
          imgUrl="/images/departments/banner_img_3.png"
          bgUrl="/images/departments/banner_bg_3.svg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
}
