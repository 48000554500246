import React, { useState } from 'react';

export default function ReplyWidget({ title, addComment }) {
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addComment(author, content);
    setAuthor('');
    setContent('');
  };

  return (
    <>
      <h3 className="cs_semibold cs_fs_24 mb-0">{title}</h3>
      <div className="cs_height_12" />
      <p className="cs_fs_18">
        untuk menjaga kerahasiaan anda, email tidak ditampilkna *
      </p>
      <div className="cs_height_7" />
      <form onSubmit={handleSubmit}>
        <label className="cs_input_label cs_heading_color cs_fs_18 cs_medium">
          Comment*
        </label>
        <textarea
          cols={30}
          rows={8}
          className="cs_form_field_2"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <div className="cs_height_20" />
        <label className="cs_input_label cs_heading_color cs_fs_18 cs_medium">
          Nama*
        </label>
        <input
          type="text"
          className="cs_form_field_2"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
        <div className="cs_height_20" />
        <label className="cs_input_label cs_heading_color cs_fs_18 cs_medium">
          Alamat Email*
        </label>
        <input type="email" className="cs_form_field_2" required />
        <div className="cs_height_30" />
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Simpan nama dan email pada Browser anda saat ingin komentar kembali.
          </label>
        </div>
        <div className="cs_height_60" />
        <button className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}