import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

export default function FloatingWhatsAppComponent() {
  return (
    <FloatingWhatsApp
      phoneNumber="6285921985701"
      accountName="Aku Sehat Admin"
      avatar="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
      chatMessage="Hai kak, ada bisa kami bantu?"
      darkMode={false}
      size="60px"
      side="right"
    />
  );
}