import React from 'react';

const Pagination2 = ({ items }) => {
  // Pastikan items tidak undefined
  if (!items || !Array.isArray(items)) {
    return null; // Atau tampilkan pesan kesalahan
  }

  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>{item.name}</div>
      ))}
    </div>
  );
};

export default Pagination2;