// src/data/Author.js
const authorData = [
    {
        id: 1,
        imgUrl: '/images/blog/ala-unas.jpg',
        name: 'Ala Unas B.',
        description: `Penulis, content-creator dan penggiat wisata kesehatan (health tourism) sejak 2020. Kunjungi instagram @alabariklana.`,
    },
    {
        id: 2,
        imgUrl: '/images/blog/andi-nisa.jpg',
        name: 'Andi Nurhaerunnisa',
        description: `Seorang penyuluh kesehatan masyarakat profesional, Sarjana Kesmas dibidang gizi. aktif dalam banyak kegiatan sosial, Content Creator Kesehatan di Aku Sehat.`,
    },
    {
        id: 3,
        imgUrl: '/images/blog/jane-smith.jpg',
        name: 'dr. Aswadi Ibrahim Sp.Ak',
        description: `Pakar pemasaran digital dan pembicara publik. Ikuti dia di Twitter @janesmith.`,
    },
    {
        id: 4,
        imgUrl: '/images/blog/mark-twain.jpg',
        name: 'Mark Twain',
        description: `Penulis terkenal dan humoris. Baca karya-karyanya di marktwain.com.`,
    },
    {
        id: 5,
        imgUrl: '/images/blog/emily-bronte.jpg',
        name: 'Emily Bronte',
        description: `Penulis novel klasik dan penyair. Temukan puisinya di emilybronte.com.`,
    },
];

export default authorData;